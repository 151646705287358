<template>
    <div class="successCase-map">
        <div class="successCase-map-container">
            <transition>
                <div class="successCase-map-modal" v-if="currentModalData && currentModalData.name && currentModalData.data && currentModalData.data.length">
                    <svg @click="onClose" class="successCase-map-modal-close" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.092 19.092L6.364 6.364M6.364 19.092L19.092 6.364" stroke="#DCDCDC" stroke-width="2" stroke-linecap="round"/></svg>
                    <div class="poppins successCase-map-modal-name">{{ currentModalData.name }}</div>
                    <div class="successCase-map-modal-list">
                        <div class="successCase-map-modal-item" :class="{ pointer: item.linkId }" @click="onBrandClick(item)" v-for="(item, index) in currentModalData.data" :key="index">
                            <img :src="item.logo" alt="" class="logo" />
                            <div class="name">{{ item.name }}</div>
                            <div class="icon-arrow" v-if="item.linkId"></div>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="successCase-map-item" :class="[list.key]" v-for="list in group" :key="list.key">
                <div class="successCase-map-item-tip" @click="onTipClick(item)" v-for="(item, index) in list.data" :key="index">
                    <div class="arrow" :class="[list.direction]" v-if="index === 0"></div>
                    <div class="name">{{ item.name }}</div>
                    <div class="num">{{ item.data.length }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentModalData: {},
        };
    },
    computed: {
        group() {
            let i18n = this.$t('successCasePage');
            return [
                {
                    direction: 'right',
                    key: 'beijing',
                    data: [
                        {
                            name: i18n.beijing,
                            data: [
                                {
                                    name: i18n.beijing1,
                                    logo: require('@assets/images/successCase/logo/kslcjh.png'),
                                },
                            ],
                        },
                    ],
                },
                {
                    direction: 'right',
                    key: 'shanghai',
                    data: [
                        {
                            name: i18n.shanghai,
                            data: [
                                {
                                    name: i18n.bauschLomb,
                                    logo: require('@assets/images/successCase/logo/bausch-lomb.png'),
                                },
                                {
                                    linkId: 6,
                                    name: i18n.EFFECTIM,
                                    logo: require('@assets/images/successCase/logo/effectim.png'),
                                },
                                {
                                    linkId: 1,
                                    name: i18n.DIOR,
                                    logo: require('@assets/images/successCase/logo/dior.png'),
                                },
                                {
                                    name: i18n.Sisley,
                                    logo: require('@assets/images/successCase/logo/sisley.png'),
                                },
                                {
                                    name: i18n.Pechoin,
                                    logo: require('@assets/images/successCase/logo/pechoin.png'),
                                },
                                // {
                                //     name: i18n.Sciences,
                                //     logo: require('@assets/images/successCase/logo/zky.png'),
                                // },
                                {
                                    name: i18n.Dermatology,
                                    logo: require('@assets/images/successCase/logo/shpfyy.png'),
                                },
                                {
                                    name: i18n.UNISKIN,
                                    logo: require('@assets/images/successCase/logo/uniskin.png'),
                                },
                                {
                                    name: i18n.LVMH,
                                    logo: require('@assets/images/successCase/logo/lvmh.png'),
                                },
                            ],
                        },
                    ],
                },
                {
                    direction: 'right',
                    key: 'thailand',
                    data: [
                        {
                            name: i18n.Thailand,
                            data: [
                                {
                                    name: i18n.KINGPOWER,
                                    logo: require('@assets/images/successCase/logo/kingpower.png'),
                                },
                            ],
                        },
                    ],
                },
                {
                    direction: 'left',
                    key: 'tokyo',
                    data: [
                        {
                            name: i18n.Tokyo,
                            data: [
                                {
                                    linkId: 6,
                                    name: i18n.EFFECTIM,
                                    logo: require('@assets/images/successCase/logo/effectim.png'),
                                },
                                {
                                    name: i18n.Angelux,
                                    logo: require('@assets/images/successCase/logo/angelux.png'),
                                },
                            ],
                        },
                    ],
                },
                {
                    direction: 'left',
                    key: 'guangdong',
                    data: [
                        {
                            name: i18n.Guangzhou,
                            data: [
                                {
                                    name: i18n.Fanwenhua,
                                    logo: require('@assets/images/successCase/logo/fwh.png'),
                                },
                            ],
                        },
                        {
                            name: i18n.Shenzhen,
                            data: [
                                {
                                    name: i18n.shenzhen1,
                                    logo: require('@assets/images/successCase/logo/zsdx.png'),
                                },
                            ],
                        },
                        {
                            name: i18n.Macao,
                            data: [
                                {
                                    name: i18n.DFS,
                                    logo: require('@assets/images/successCase/logo/dfs.png'),
                                },
                            ],
                        },
                    ],
                },
                // {
                //     direction: 'right',
                //     key: 'ireland',
                //     data: [
                //         {
                //             name: 'Dublin',
                //             data: [
                //                 {
                //                     name: 'CE.way',
                //                     logo: require('@assets/images/successCase/logo/ce.png'),
                //                 },
                //             ],
                //         },
                //     ],
                // },
                {
                    direction: 'left',
                    key: 'france',
                    data: [
                        {
                            name: i18n.Paris,
                            data: [
                                {
                                    linkId: 1,
                                    name: i18n.DIOR,
                                    logo: require('@assets/images/successCase/logo/dior.png'),
                                },
                                {
                                    name: i18n.LVMH,
                                    logo: require('@assets/images/successCase/logo/lvmh.png'),
                                },
                            ],
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        onTipClick(data) {
            this.currentModalData = data;
        },
        onClose() {
            this.currentModalData = {};
        },
        onBrandClick(data) {
            if (!data.linkId) return;

            this.$router.push({
                name: 'newsDetail',
                params: {
                    id: data.linkId,
                },
            });
        }
    },
};
</script>

<style lang="scss">
.successCase-map {
    .icon-arrow {
        background: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.172 6.828L6 4 3.172 1.172' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") 0 0 no-repeat;
        background-size: contain;
    }
    @media only screen and (min-width: 769px) {
        height: 635px;
        margin-bottom: 100px;
        background: #D4E6F6;
        overflow: hidden;

        &-container {
            width: 1277px;
            height: 588px;
            margin: 40px auto 0 auto;
            background: url(~@assets/images/successCase/map.jpg) 0 0 no-repeat;
            background-size: contain;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-item {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &-tip {
                display: flex;
                align-items: center;
                padding: 0 10px;
                height: 30px;
                border-radius: 5px;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.11);
                background: #fff;
                margin-bottom: 4px;
                cursor: pointer;

                .arrow {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    position: absolute;
                    top: 10px;

                    &.left {
                        border-width: 5px 6px 5px 0;
                        border-color: transparent #fff transparent transparent;
                        left: -6px;
                    }
                    &.right {
                        right: -6px;
                        border-width: 5px 0 5px 6px;
                        border-color: transparent transparent transparent #fff;
                    }
                }
                .name {
                    font-size: 12px;
                    font-weight: 500;
                }
                .num {
                    width: 18px;
                    height: 18px;
                    border-radius: 10px;
                    background: #00C8C8;
                    font-size: 10px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 4px;
                }
            }

            &.beijing {
                top: 138px;
                left: 858px;
            }
            &.shanghai {
                left: 872px;
                top: 195px;
            }
            &.thailand {
                left: 823px;
                top: 250px;
            }
            &.tokyo {
                top: 170px;
                left: 1034px;
            }
            &.guangdong {
                left: 980px;
                top: 218px;
            }
            &.ireland {
                top: 100px;
                left: 470px;
            }
            &.france {
                top: 120px;
                left: 600px;
            }
        }

        &-modal {
            width: 260px;
            box-sizing: border-box;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
            border-radius: 15px;
            background: #f9f9f9;
            position: relative;

            &-close {
                position: absolute;
                top: 11px;
                right: 11px;
                cursor: pointer;
            }

            &-name {
                font-size: 20px;
                line-height: 1;
                font-weight: 600;
                padding: 15px;
            }
            &-list {
                max-height: 230px;
                overflow-y: auto;
                padding: 0 15px;
            }
            &-item {
                background: #fff;
                margin: 5px 0;
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
                border-radius: 10px;

                &.pointer {
                    cursor: pointer;
                }
                &:last-of-type {
                    margin-bottom: 10px;
                }

                .logo {
                    width: 56px;
                    height: 56px;
                    margin-right: 2px;
                    flex-shrink: 0;
                }
                .name {
                    width: 150px;
                    font-size: 13px;
                    line-height: 16px;
                    font-weight: 500;
                    padding: 12px 0;
                }
                .icon-arrow {
                    width: 8px;
                    height: 8px;
                    margin-left: 3px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        width: 3.75rem;
        height: 5.05rem;
        margin-bottom: 0.6rem;
        background: url(~@assets/images/successCase/mmap.jpg) 0 0 no-repeat;
        background-size: contain;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
            position: absolute;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &-tip {
                display: flex;
                align-items: center;
                height: 0.38rem;
                padding: 0 0.1rem;
                border-radius: 0.05rem;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.11);
                background: #fff;
                margin-bottom: 4px;

                border-radius: 5px;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.11);
                background: #fff;
                margin-bottom: 4px;
                cursor: pointer;

                .arrow {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    position: absolute;
                    top: 0.14rem;

                    &.left {
                        border-width: 0.05rem 0.06rem 0.05rem 0;
                        border-color: transparent #fff transparent transparent;
                        left: -0.05rem;
                    }
                    &.right {
                        right: -0.05rem;
                        border-width: 0.05rem 0 0.05rem 0.06rem;
                        border-color: transparent transparent transparent #fff;
                    }
                }
                .name {
                    font-size: 14px;
                    font-weight: 400;
                }
                .num {
                    width: 0.2rem;
                    height: 0.2rem;
                    border-radius: 10px;
                    background: #00C8C8;
                    font-size: 12px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0.04rem;
                }
            }

            &.beijing {
                left: 0.74rem;
                top: 1.39rem;
            }
            &.shanghai {
                left: 0.82rem;
                top: 1.83rem;
            }
            &.thailand {
                left: 0.36rem;
                top: 2.3rem;
            }
            &.tokyo {
                left: 2.4rem;
                top: 1.6rem;
            }
            &.guangdong {
                left: 1.98rem;
                top: 2.09rem;
            }
            &.ireland,
            &.france {
                display: none;
            }
        }

        &-modal {
            width: 2.58rem;
            box-sizing: border-box;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
            border-radius: 15px;
            background: #f9f9f9;
            position: relative;
            z-index: 5;

            &-close {
                width: 0.18rem;
                height: 0.18rem;
                position: absolute;
                top: 0.14rem;
                right: 0.14rem;
            }
            &-name {
                font-size: 18px;
                font-weight: 600;
                padding: 0.2rem 0.15rem 0.1rem 0.15rem;
            }
            &-list {
                max-height: 2rem;
                overflow-y: auto;
                padding: 0 0.15rem;
            }
            &-item {
                background: #fff;
                margin: 0.04rem 0;
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
                border-radius: 0.05rem;
                &:last-of-type {
                    margin-bottom: 0.08rem;
                }
                .logo {
                    width: 0.55rem;
                    height: 0.55rem;
                    margin-right: 2px;
                    flex-shrink: 0;
                }
                .name {
                    width: 1.48rem;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 400;
                    padding: 0.12rem 0;
                }
                .icon-arrow {
                    width: 0.08rem;
                    height: 0.08rem;
                    margin-left: 3px;
                }
            }
        }
    }
}
</style>
