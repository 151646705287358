<template>
    <div class="successCase-news">
        <div class="successCase-news-ani successCase-news-item" v-for="item in list" :key="item.id">
            <div class="successCase-news-item-image">
                <img :src="require(`@assets/images/about/newsDetail/${item.id}/${item.img}`)" alt="" />
            </div>
            <div class="successCase-news-item-info">
                <div class="title">{{ item.title }}</div>
                <div class="date">{{ item.date || '' }}</div>
                <div class="desc">{{ item.desc }}</div>
                <a v-if="item.linkId" href="javascript:;" @click="onMore(item)" class="poppins more">{{ $t('more') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        newsI18n() {
            return this.$t('newsFile');
        },
        list() {
            let { list, successCase } = this.newsI18n;
            let listMap = {};
            list.forEach(item => {
                listMap[item.id] = item;
            });
            let result = [];
            successCase.forEach(item => {
                if (listMap[item]) {
                    result.push(listMap[item]);
                }
            });
            return result;
        },
    },
    methods: {
        onMore(data) {
            if (data.linkId) {
                this.$track('successcase_case_clk', {
                    track_id: data.id,
                });
                this.$router.push({
                    name: 'newsDetail',
                    params: {
                        id: data.id,
                    },
                });
            }
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.successCase-news-ani',
            triggerElement: '.successCase-news',
            triggerHook: 0.8,
        });
    },
};
</script>

<style lang="scss">
.successCase-news {
    @media only screen and (min-width: 769px) {
        width: 800px;
        margin: 0 auto;

        &-item {
            display: flex;
            margin-bottom: 60px;

            &-image {
                width: 300px;
                flex-shrink: 0;
                margin-right: 50px;

                img {
                    display: block;
                    width: 100%;
                }
            }
            &-info {
                .title {
                    font-size: 20px;
                    font-weight: 600;
                }
                .date {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;
                    margin-top: 10px;
                    margin-bottom: 15px;
                }
                .desc {
                    font-size: 16px;
                    font-weight: 300;
                    color: #333;
                }
                .more {
                    width: 180px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: solid 2px #000;
                    font-size: 20px;
                    font-weight: 500;
                    border-radius: 30px;
                    margin-top: 10px;
                    color: #000;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 0 0.32rem 0.8rem 0.32rem;

        &-item {
            margin-bottom: 0.55rem;
            &:last-of-type {
                margin-bottom: 0;
            }
            &-image {
                width: 100%;
                margin-bottom: 0.15rem;

                img {
                    display: block;
                    width: 100%;
                }
            }
            &-info {
                .title {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 0.15rem;
                }
                .date {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;
                    margin-bottom: 0.15rem;
                }
                .desc {
                    display: none;
                }
                .more {
                    width: 1.2rem;
                    height: 0.32rem;
                    border: solid 1px #000;
                    border-radius: 0.32rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                }
            }
        }
    }
}
</style>
