<template>
    <div class="Page successCase">
        <smap></smap>
        <list></list>
    </div>
</template>

<script>
import smap from './components/map.vue';
import list from './components/list.vue';
export default {
    name: 'successCase',
    components: {
        smap,
        list,
    },
    data() {
        return {
            pageDurationID: 'Successcase',
        };
    },
    mounted() {
        this.$track('successcase_imp');
    },
};
</script>
